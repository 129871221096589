<template>
  <b-row>
    <b-col v-if="!digitaValores">
      <b-card>
        <b-row>
          <b-col md="8">
            <h4>Nota Fiscal Emitida</h4>
          </b-col>
          <b-col md="4">
            <b-button
              variant="outline-success"
              class="mb-75"
              fixed
              block
              @click="emitirNovaNota()"
            > EMITIR NOVA NOTA
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <embed
              :src="`https://nfeapi.devn1.com/api-nfe/danfe/index.php?cnpj=6073584000101&chave=${chaveNota}`"
              width="100%"
              height="500px"
            >
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col v-if="digitaValores">
      <b-card>
        <b-row>
          <h4>NF Detalhada</h4>
        </b-row>
        <b-row>
          <b-col
            md="4"
          >
            <b-form-group
              label="CPF ou CNPJ"
              label-for="cpfcnpj"
            >
              <b-form-input
                id="cpfcnpj"
                v-model="cpfcnpjBDL"
                placeholder="CPF ou CNPJ"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
          >
            <b-form-group
              label="Email Do CLiente"
              label-for="emailcli"
            >
              <b-form-input
                id="emailcli"
                v-model="emailcliBDL"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-for="produto in produtos"
            :key="produto.id"
            md="4"
          >
            <b-form-group
              :label-for="produto.nome"
              :label="produto.nome"
            >
              <money
                :id="produto.nome"
                v-model="produto.valor"
                v-money="money"
                v-bind="money"
                :placeholder="produto.nome"
                class="form-control"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2">
            <b-button
              variant="outline-success"
              class="mb-75"

              fixed
              block
              @click="SalvarDados"
            > EMITIR NFE
            </b-button>
          </b-col>
          <b-col md="4">
            <b-button
              variant="outline-warning"
              class="mb-75"
              fixed
              block
              @click="zerarCampos"
            > Zerar Campos
            </b-button>
          </b-col>
          <b-col
            md="6"
            style="text-align: right;"
          >
            <h3>Valor total da nota: {{ valorTotal | currency }}</h3>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { VBToggle } from 'bootstrap-vue'
import { VMoney } from 'v-money'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    money: VMoney,
  },
  mixins: [heightTransition],
  data() {
    return {
      isLoading: false,
      fullPage: true,
      chaveNota: '',
      digitaValores: true,
      valorTotal: 0.00,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      id: null,
      cnpj: '6073584000101',
      cpfcnpjBDL: '',
      emailcliBDL: '',
      produtos: [],
      mostraPopUp: true,
    }
  },
  updated() {
    let total = 0
    this.produtos.forEach(prod => {
      if (prod.valor !== 0) {
        total += prod.valor
      }
    })
    this.valorTotal = total
  },
  mounted() {
    this.carregaProdutos()
  },
  methods: {
    zerarCampos() {
      document.location.reload(true)
    },
    emitirNovaNota() {
      this.digitaValores = true
      this.zerarCampos()
    },
    carregaProdutos() {
      this.produtos = [
        {
          id: 17,
          nome: 'Refeição',
          un: 'UN',
          ncm: '22030000',
          cest: '0302100',
          icms: '500',
          pis: '04',
          cofins: '04',
          cfop: '5405',
          valor: 0,
        },
      ]
    },
    async SalvarDados() {
      this.$loading(true)
      const produtos = []
      let total = []
      this.produtos.forEach(prod => {
        if (prod.valor !== 0) {
          total += prod.valor
          produtos.push({
            codigo: prod.id,
            nome: prod.nome,
            tipo: prod.un,
            ncm: prod.ncm,
            cest: prod.cest,
            icms: prod.icms,
            qtd: 1,
            pis: prod.pis,
            cofins: prod.cofins,
            cfop: prod.cfop,
            subtotal: prod.valor,
            total: prod.valor,
          })
        }
      })
      const obj = {
        cpf: this.cpfcnpjBDL,
        email: this.emailcliBDL,
        cnpj: this.cnpj,
        total,
        produtos,
      }
      try {
        // eslint-disable-next-line consistent-return
        await this.$http.post('https://nfeapi.devn1.com/api/gn', obj).then(resp => {
          if (!resp.data.status) return this.$toast('Erro ao gerar nota')
          this.chaveNota = resp.data.chave
          this.digitaValores = false
        })
      } catch {
        this.$toast('Erro ao gerar nota')
      }
      this.$loading(false)
    }
    ,
  }
  ,
}
</script>

<style scoped>

</style>
